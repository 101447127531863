import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import Features from '../components/Features';
import BlogRoll from '../components/BlogRoll';

import Content, { HTMLContent } from '../components/Content';

import classess from './index.module.scss';
import ContentContainer from '../components/Layouts/ContentContainer';
import Jumbotron from '../components/Jumbotron';

export const IndexPageTemplate = ({
  title,
  jumbotron,
  mainpitch,
  intro,
  content,
  contentComponent,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <div>
      {/* Jumbotron
      =============================================================*/}
      <Jumbotron jumbotron={jumbotron}></Jumbotron>

      {/* Content Start
      =============================================================*/}

      <ContentContainer>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="content">
              {/* Mainpitch
               *************************************************/}
              <div className="content">
                <div className="tile">
                  <h1 className={`title ${classess.pitchTitle}`}>
                    {mainpitch.title}
                  </h1>
                </div>
                <div className="tile">
                  <h3 className={`subtitle ${classess.pitchSubtitle}`}>
                    {mainpitch.description}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentContainer>

      <div className={classess.callToActionBox}>
        <Link className={classess.callToActionButton} to="categories">
          Explore our Products and Services
        </Link>
      </div>

      <ContentContainer>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="content">
              {/* Intro - Blurbs
               *************************************************/}
              <div className="columns">
                <div className="column is-12">
                  <h3 className="has-text-weight-semibold is-size-2">
                    {intro.heading}
                  </h3>
                  <p>{intro.description}</p>
                </div>
              </div>
              <Features gridItems={intro.blurbs} />

              {/* Only add the button if `button.text` exist */}
              {intro.button && intro.button.text && (
                <div className="columns">
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to={intro.button.link}>
                      {intro.button.text}
                    </Link>
                  </div>
                </div>
              )}

              {/* Main Content Body - General Markdown
               *************************************************/}
              {/* Add this section only if `content` exist */}
              {content && (
                <div className="columns">
                  <div className="column is-12">
                    <PostContent className="content" content={content} />
                  </div>
                </div>
              )}

              {/* Load Latest Blog Posts
               *************************************************/}
              <div className="column is-12">
                <h3 className="has-text-weight-semibold is-size-2">
                  Latest stories
                </h3>
                <BlogRoll />
                <div className="column is-12 has-text-centered">
                  <Link className="btn" to="/blog">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentContainer>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  jumbotron: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    heading: PropTypes.string,
    subheading: PropTypes.string,
  }),
  mainpitch: PropTypes.object,
  intro: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    blurbs: PropTypes.array,
    button: PropTypes.object, // { text, link }
  }),
  content: PropTypes.any,
  contentComponent: PropTypes.func,
};

// This is what will be used by React to generate a page.
// Accept request from GraphQL query that we exported
// and strap it to our template
const IndexPage = ({ data }) => {
  const { html, frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        jumbotron={frontmatter.jumbotron}
        mainpitch={frontmatter.mainpitch}
        intro={frontmatter.intro}
        content={html}
        contentComponent={HTMLContent}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

// Specify what variables to request through GraphQL
export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
        jumbotron {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          heading
          subheading
        }
        mainpitch {
          title
          description
        }
        intro {
          heading
          description
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
            text
          }
          button {
            text
            link
          }
        }
      }
    }
  }
`;
